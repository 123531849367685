import React, { useState, useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'
import _find from 'lodash/find'
import _groupBy from 'lodash/groupBy'
import _capitalize from 'lodash/capitalize'

import Button from 'src/components/Button'
import SafeImage from 'src/components/SafeImage'
import H2 from 'src/components/H2'
import { Tile, Content } from 'src/components/Tile/BaseTile'
import Head from 'src/components/Head'
import { useSelector, useDispatch } from 'react-redux'
import { useInternationalisation } from 'src/context'
import SavedHeart from 'src/components/SavedHeart'
import { savedSelector, attributesSelector } from 'src/store/account/selectors'
import { createSaved, removeSaved } from 'src/store/account/actionCreators'

import FiltersMenu from './../FiltersMenu'

import { showHeartResourceTypes } from 'src/utility'

import { similarProductsSelector } from 'src/store/styleSeeker/selectors'
import {
  setSelectedProduct,
  openFiltersMenu,
  setCurrentPath
} from 'src/store/styleSeeker/actionCreators'

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 8%
    ${(p) => (p.padBottom ? 'calc(85px + env(safe-area-inset-bottom))' : 0)} 8%;
`

const Title = styled.h2`
  font-size: 22px;
  font-weight: 700;
  padding: 15px;
`

const Details = styled.div`
  font-size: 16px;
  margin: 0;
`

const SimilarProducts = (props) => {
  const { history, onProductClick, onProductSave } = props

  const dispatch = useDispatch()
  const { translate, translateUrl } = useInternationalisation()
  const similar = useSelector(similarProductsSelector())
  const saved = useSelector(savedSelector())
  const userAttributes = useSelector(attributesSelector())

  const handleOnFiltersClick = useCallback(() => {
    dispatch(openFiltersMenu())
  }, [])

  const Product = (product) => {
    const id = _get(product, 'id')
    const metadata = _get(product, 'metadata')
    const imageUrl = _get(product, 'imageUrl')
    const category = _get(product, 'categoryAlias')

    const {
      title,
      Retailer: retailer,
      brandName: brand,
      price: rawPrice,
      Currency: currency,
      description
    } = metadata || {}
    const price = rawPrice
      ? new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }).format(rawPrice)
      : null

    const showSavedHeart = showHeartResourceTypes.includes('product')

    const savedId = _get(
      _find(
        saved,
        (item) => item.content_id === id && item.content_type === 'product'
      ),
      'id',
      null
    )

    return (
      <Tile
        key={`product_${id}`}
        id={id}
        onClick={() => {
          onProductClick(product)
          history.push(translateUrl(`/style-seeker/product-details`))
        }}
      >
        <Content style={{ padding: 10, marginTop: 20 }}>
          {showSavedHeart && (
            <SavedHeart
              size={40}
              saved={savedId}
              handleOnHeartClick={() =>
                onProductSave({
                  savedId,
                  content_id: id,
                  content_type: 'product',
                  content_category: category
                })
              }
            />
          )}
          <SafeImage src={imageUrl} height={200} width={''} alt='Product' />
          <Title>{title}</Title>
          <Details>{translate('STYLE_SEEKER_BRAND', { name: brand })}</Details>
          <Details>
            {translate('STYLE_SEEKER_RETAILER', { name: retailer })}
          </Details>
          <Details>{price}</Details>
        </Content>
      </Tile>
    )
  }

  return (
    <Wrapper padBottom>
      <Head title={translate('PAGE_STYLE_SEEKER')} />
      <Button
        buttonType={'primary'}
        onClick={handleOnFiltersClick}
        style={{ width: '100%', margin: '15px 0', height: 70 }}
      >
        {translate('STYLE_SEEKER_FILTER')}
      </Button>
      <div style={{ flex: '1 1 auto', fontSize: 22, fontWeight: 700 }}>
        {translate('SEARCH_RESULTS_COUNT', { count: _size(similar) })}
      </div>
      <FiltersMenu />
      {_map(similar, Product)}
    </Wrapper>
  )
}

export default SimilarProducts
