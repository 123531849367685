import React from 'react'
import styled from 'styled-components'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import options from './renderOptions'

const RichTextContainer = styled.div`
  width: 100%;
  max-width: 100%;
  img {
    display: block;
    margin: auto;
  }
`

const renderRichText = (content) => (
  <RichTextContainer>
    {documentToReactComponents(content, options)}
  </RichTextContainer>
)

export default renderRichText
