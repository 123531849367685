import React, { Fragment, useCallback } from 'react'
import styled from 'styled-components'
import { withRouter } from 'src/components/RouterDom'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _flow from 'lodash/flow'
import _startCase from 'lodash/startCase'
import _size from 'lodash/size'
import _compact from 'lodash/compact'
import _ from 'lodash'
import Container from 'src/components/Container'
import Box from 'src/components/Box'
import PageBanner from 'src/components/PageBanner'
import BackBar from 'src/components/BackBar'
import FeaturedTiles from 'src/components/Tile/FeaturedTiles'
import Spacing from 'src/components/Spacing'
import SocialShareButtons from 'src/components/SocialShareButtons'
import {
  getResourceType,
  getAssetSrc,
  convertCategoryTypeToUrl,
  getFields,
  getFirstResource
} from 'src/utility'
import vars from 'src/styling/vars'
import { isCordova } from 'src/env'
import H2 from 'src/components/H2'
import DateTime from 'src/components/DateTime'
import Button from 'src/components/Button'
import { getValidResources } from 'src/components/Tile/getValidResources'
import { getEventDatesRange, showHeartResourceTypes } from 'src/utility'
import { useSelector, useDispatch } from 'react-redux'
import { createSaved, removeSaved } from 'src/store/account/actionCreators'
import { savedSelector, attributesSelector } from 'src/store/account/selectors'
import { useInternationalisation } from 'src/context'

const DateContainer = styled.div`
  background-color: #262a30;
  color: #ffffff;
  padding: 10px 20px;
  margin-bottom: 22px;
`

const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const Wrapper = ({ children, inner, box }) => {
  switch (true) {
    case inner && box:
      return (
        <Fragment>
          <Box>
            <Inner>{children}</Inner>
          </Box>
        </Fragment>
      )
    case box:
      return <Box>{children}</Box>
    case inner:
      return <Inner>{children}</Inner>
    default:
      return <Fragment>{children}</Fragment>
  }
}

const getDetailProps = (resource, translate, { location }) => {
  if (getResourceType(resource) === 'event') {
    const fields = getFields(resource)
    return {
      headerImage: fields.headerImage,
      headerImageMobile: fields.headerImageMobile,
      title: fields.title,
      listImage: fields.listImage,
      relatedOtherContent: fields.relatedOtherContent || [],
      relatedRetailUnits: fields.relatedRetailUnits || [],
      backBarProps: {
        link: '/events',
        text: translate('BACK_TO', { type: translate('EVENTS') })
      },
      summary: fields.summary,
      startDate: fields.startDate,
      endDate: fields.endDate,
      isPlusExclusive: fields.isPlusExclusive
    }
  }
  if (getResourceType(resource) === 'promotion') {
    const fields = getFields(resource)
    const checkAttachmentExists = _.get(fields, 'promotionAttachment')
    const { categoryType } = fields
    const type = convertCategoryTypeToUrl(categoryType)
    const { link, text } = _get(location, 'state.backBarProps', {})
    return {
      headerImage: fields.headerImage,
      headerImageMobile: fields.headerImageMobile,
      title: fields.title,
      listImage: fields.listImage,
      relatedOtherContent: fields.relatedOtherContent || [],
      relatedRetailUnits: fields.relatedRetailUnits || [],
      backBarProps: {
        link: link || `/promotions`,
        text: translate('BACK_TO', { type: text || translate('PROMOTIONS') })
      },
      summary: fields.summary,
      startDate: fields.startDate,
      endDate: fields.endDate,
      ctaTitle: translate('VOUCHER_DOWNLOAD'),
      ctaAction: () => {
        let ctaDownloadUrl = getAssetSrc(fields.voucher)
        window.open(ctaDownloadUrl, '_blank')
      },
      showCta: fields.anonymousVoucherDownloadAllowed,
      isPlusExclusive: fields.isPlusExclusive,
      attachmentTitle: translate('PROMOTION_DOWNLOAD'),
      attachmentClicked: () => {
        let ctaDownloadUrl = getAssetSrc(checkAttachmentExists)
        window.open(ctaDownloadUrl, '_blank')
      },
      showAttachmentCta: _.get(fields, 'promotionDownloadAllowed'),
      checkAttachmentExists
    }
  }
  if (getResourceType(resource) === 'news') {
    const fields = getFields(resource)
    const checkAttachmentExists = _.get(fields, 'newsAttachment')
    return {
      headerImage: fields.headerImage,
      headerImageMobile: fields.headerImageMobile,
      title: fields.title,
      listImage: fields.listImage,
      relatedOtherContent: fields.relatedOtherContent || [],
      relatedRetailUnits: fields.relatedRetailUnits || [],
      backBarProps: {
        link: '/news',
        text: translate('BACK_TO', { type: translate('NEWS') })
      },
      summary: fields.summary,
      startDate: fields.displayFrom,
      endDate: fields.displayFrom,
      attachmentTitle: translate('NEWS_DOWNLOAD'),
      attachmentClicked: () => {
        let ctaDownloadUrl = getAssetSrc(checkAttachmentExists)
        window.open(ctaDownloadUrl, '_blank')
      },
      showAttachmentCta: _.get(fields, 'newsDownloadAllowed'),
      checkAttachmentExists
    }
  }
}

const DetailsPage = (props) => {
  const {
    request,
    resource = getFirstResource(request),
    children,
    history,
    location,
    LoadingPlaceholder,
    inner
  } = props
  const isLoading = _get(request, '_status.pending')
  const { translate, moment, translateUrl, defaultLocale } = useInternationalisation()
  const details = React.useMemo(() => {
    const details = getDetailProps(resource, translate, { location }) || {}
    const { relatedOtherContent } = details
    const validDetails = {
      ...details,
      relatedOtherContent: getValidResources(relatedOtherContent)
    }
    return _.defaults(validDetails, {
      title: '',
      listImage: {},
      relatedOtherContent: [],
      relatedRetailUnits: [],
      backBarProps: {},
      isPlusExclusive: false
    })
  }, [resource, translate])
  const wrapperProps = { inner: inner || false, box: true }
  const userAttributes = useSelector(attributesSelector())

  const dispatch = useDispatch()
  const handleOnHeartClick = useCallback(
    ({ savedId, content_id, content_type }) => {
      if (!userAttributes) history.push(translateUrl('/saved'))
      if (!savedId) {
        dispatch(
          createSaved({
            content_id,
            content_type
          })
        )
      } else {
        dispatch(
          removeSaved({
            id: savedId
          })
        )
      }
    },
    []
  )

  const saved = useSelector(savedSelector())
  const content_type = getResourceType(resource)
  const content_id = _.get(resource, 'id')
  const savedMeta = {
    showSavedHeart: showHeartResourceTypes.includes(content_type),
    savedId: _get(
      _.find(
        saved,
        (item) =>
          item.content_id === content_id && item.content_type === content_type
      ),
      'id',
      null
    ),
    content_id,
    content_type,
    handleOnHeartClick
  }

  const shareMessage = _compact([
    _.get(details, 'title'),
    _.get(details, 'summary')
  ]).join(' - ')

  return (
    <>
      <PageBanner
        text={details.title}
        bannerMediaDesktop={details.headerImage}
        bannerMediaMobile={details.headerImageMobile}
        savedMeta={savedMeta}
        isPlusExclusive={details.isPlusExclusive}
      />
      <Container topGutter={!!isCordova} bottomGutter>
        {!isCordova && (
          <BackBar
            link={details.backBarProps.link}
            text={details.backBarProps.text}
          />
        )}
        <>
          <Wrapper {...wrapperProps}>
            {isLoading && LoadingPlaceholder ? (
              <LoadingPlaceholder />
            ) : details.startDate && details.endDate ? (
              <DateContainer>
                <DateTime
                  {...getEventDatesRange({
                    moment,
                    localeCode: defaultLocale,
                    from: details.startDate,
                    to: details.endDate
                  })}
                />
              </DateContainer>
            ) : null}
            {children}
            {((details.showAttachmentCta && details.checkAttachmentExists) || details.showCta) ? (
              <ButtonsContainer>
                {details.showCta ? (
                  <Button buttonType='primary' onClick={details.ctaAction}>
                    {details.ctaTitle}
                  </Button>
                ) : null}
                {details.showAttachmentCta && details.checkAttachmentExists ? (
                  <Button
                    buttonType={details.showCta ? 'secondary' : 'primary'}
                    onClick={details.attachmentClicked}
                  >
                    {details.attachmentTitle}
                  </Button>
                ) : null}
              </ButtonsContainer>
            ) : null}

            <SocialShareButtons message={shareMessage} image={details.listImage} />
          </Wrapper>
          {details.relatedOtherContent.length ||
            details.relatedRetailUnits.length ? (
              <Spacing height={vars.pageGutter} />
            ) : null}
          {details.relatedOtherContent.length ? (
            <FeaturedTiles
              title={translate('NEWS_AND_OFFERS')}
              resources={details.relatedOtherContent}
            />
          ) : null}
          {details.relatedRetailUnits.length ? (
            <FeaturedTiles
              title={translate('RELATED_SHOPS')}
              resources={details.relatedRetailUnits}
            />
          ) : null}
        </>
      </Container>
    </>
  )
}

export default withRouter(DetailsPage)
