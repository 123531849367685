const vars = {
  headerHeight: { sm: 120, header: 172 },
  appTabBarHeight: 60,
  maxWidth: 1440,
  pageGutter: { sm: 20, md: 40, lg: 50 },
  pageBannerHeight: { sm: 400, md: 450, lg: 500 },
  homepageBannerHeight: { sm: 640, md: 640, lg: 680 },
  zIndex: {
    header: 1,
    navigationOverlay: 2,
    modalOverlay: 3,
    appTabBar: 1,
    main: 0,
    skipNav: 2
  },
  contentShadow: `0 3px 50px 0 rgba(0, 0, 0, 0.18)`,
  innerContentMaxWidth: `650px`,
  fontFamily: 'Futura, Arial'
}
vars.tileGutter = vars.pageGutter.sm

export default vars
