import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Link, withRouter } from 'src/components/RouterDom'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _ from 'lodash'
import fp from 'lodash/fp'
import _lowerCase from 'lodash/lowerCase'
import { createSaved, removeSaved } from 'src/store/account/actionCreators'
import { savedSelector, attributesSelector } from 'src/store/account/selectors'
import { useInternationalisation } from 'src/context'
import colors from 'src/styling/colors'
import { plainList } from 'src/styling/styles'
import { getCentreConfig } from 'src/config'

import {
  PlusExclusiveBadge,
  DateTimeBadge,
  TopLeftBadge
} from 'src/components/Badges'
import tileVars from './vars'

import {
  getFields,
  getHasFields,
  getResourceType,
  getResourceId,
  createFormatTime,
  getReferencedOpeningTimes
} from 'src/utility'

import ClampLines from 'src/components/ClampLines'
import BaseTile from 'src/components/Tile/BaseTile'
import SmallArrowRight from 'src/assets/SmallArrowRight.svg'
import SavedHeart from 'src/components/SavedHeart'

import { isCordova } from 'src/env'

import {
  getAssetSrc,
  getEventDatesRange,
  showHeartResourceTypes,
  getUrl
} from 'src/utility'

const { timeFormat = {} } = getCentreConfig() || {}

const getLinkText = (category, translate) =>
  ({
    Eatery: translate('TILE_INFO_RESTAURANT'),
    Shop: translate('TILE_INFO_SHOP'),
    Leisure: translate('TILE_INFO_MORE')
  }[category] || translate('TILE_INFO_MORE'))

const getTileProps = ({ resource, match, moment, translate, defaultLocale }) => {
  const hasFields = getHasFields(resource)
  if (!hasFields) return {}
  const fields = getFields(resource)
  const entryType = getResourceType(resource)
  const entryId = getResourceId(resource)
  const formatTime = createFormatTime({ moment })({
    originFormat: 'HH:mm',
    outputFormat: timeFormat.storeOpeningTime
  })
  const todayKey = _lowerCase(moment().locale('en').format('ddd'))
  moment().locale(defaultLocale)
  let tileProps = {}
  if (entryType === 'retailUnit') {
    const { logoImage, name, openingTimes, parentCategory, showStoreOpeningTimes } = fields
    const overwriteWithReferencedOpeningHours = _.get(
      fields,
      'overwriteWithReferencedOpeningHours'
    )
    const referencedOpeningTimes = _.get(fields, 'referencedOpeningTimes')
    let openingTime, closingTime, closed, details

    if (overwriteWithReferencedOpeningHours && referencedOpeningTimes) {
      details = _.get(getReferencedOpeningTimes(referencedOpeningTimes), [
        todayKey
      ])
      openingTime = _.get(details, 'openingTime')
      closingTime = _.get(details, 'closingTime')
      closed = _.get(details, 'closed')
    } else {
      details = _.get(openingTimes, [todayKey])
      openingTime = _.get(details, 'openingTime')
      closingTime = _.get(details, 'closingTime')
      closed = _.get(details, 'closed')
    }

    let determineInfoText
    if (_.isUndefined(showStoreOpeningTimes) || showStoreOpeningTimes) {
      determineInfoText = fp.cond([
        [fp.get('allDay'), () => translate('OPEN_24HOURS')],
        [fp.get('closed'), () => translate('CLOSED_TODAY')],
        [fp.allPass([fp.get('openingTime'), fp.get('closingTime')]), () => translate('TILE_INFO_TEXT', {
          start: formatTime(openingTime),
          end: formatTime(closingTime)
        })],
        [fp.stubTrue, fp.always('')]
      ])(details)
    } else {
      determineInfoText = translate('SPECIAL_OPENING_TIMES_APPLY')
    }

    tileProps = {
      image: logoImage,
      infoText: determineInfoText,
      linkText: getLinkText(parentCategory, translate),
      title: name,
      isLogoImage: true,
      resourceType: entryType,
      contentfulId: entryId
    }
  } else if (entryType === 'promotion') {
    const {
      title,
      listImage,
      startDate,
      endDate,
      summary,
      categoryType,
      isPlusExclusive
    } = fields
    const parentCategory = _get(match, 'params.parentCategory')
    tileProps = {
      image: listImage,
      title,
      linkText: translate('VIEW', {
        type: _.toLower(translate(_.toUpper(categoryType)))
      }),
      infoText: summary,
      badgeProps: getEventDatesRange({
        moment,
        localeCode: defaultLocale,
        from: startDate,
        to: endDate
      }),
      resourceType: entryType,
      contentfulId: entryId,
      isPlusExclusive,
      to: {
        pathname: getUrl(resource),
        state: {
          backBarProps: { link: `/${parentCategory}`, text: parentCategory }
        }
      }
    }
  } else if (entryType === 'job') {
    const { jobTitle, retailUnit } = fields
    const { logoImage } = getFields(retailUnit)
    tileProps = {
      image: logoImage,
      title: jobTitle,
      linkText: translate('TILE_INFO_JOB'),
      isLogoImage: true,
      resourceType: entryType,
      contentfulId: entryId
    }
  } else if (entryType === 'news') {
    const { title, listImage, displayFrom, summary } = fields
    tileProps = {
      image: listImage,
      title,
      infoText: summary,
      badgeProps: getEventDatesRange({ moment, from: displayFrom, localeCode: defaultLocale }),
      linkText: translate('TILE_READ_MORE'),
      resourceType: entryType,
      contentfulId: entryId
    }
  } else if (entryType === 'event') {
    const {
      title,
      listImage,
      summary,
      startDate,
      endDate,
      isPlusExclusive
    } = fields
    tileProps = {
      image: listImage,
      title,
      infoText: summary,
      badgeProps: getEventDatesRange({
        localeCode: defaultLocale,
        moment,
        from: startDate,
        to: endDate
      }),
      linkText: translate('TILE_READ_MORE'),
      resourceType: entryType,
      contentfulId: entryId,
      isPlusExclusive
    }
  } else if (entryType === 'page') {
    const { title, listImage, summary } = fields
    tileProps = {
      image: listImage,
      title,
      infoText: summary,
      linkText: translate('TILE_INFO_MORE')
    }
  }
  if (!tileProps.to) {
    tileProps.to = getUrl(resource)
  }

  return tileProps
}

const ImageBox = styled.div`
  height: ${tileVars.imageHeight}px;
  width: 100%;
  display: flex;
  align-items: stretch;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
`

const Image = styled.div`
  background-image: url(${(p) => p.src});
  background-position: center center;
  background-repeat: no-repeat;
  flex: 1 1 auto;
`

const FullBleedImage = styled(Image)`
  background-size: cover;
  ${(p) => (p.src ? '' : `background-color: ${colors.textColor}`)}
`

const LogoImage = styled(Image)`
  background-size: contain;
  margin: 20px;
`

const TextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;
  padding: 12px 15px 20px;
  text-align: center;
`

const PlainLi = styled.li`
  ${plainList}
`

const ClampedText = styled(ClampLines).attrs((p) => ({
  text: p.children || '',
  lines: p.lines,
  buttons: false,
  ellipsis: '...',
  innerElement: p.innerElement || 'p'
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => p.fontSize}px;
  // instead of height we could use max-height here to make
  // the tile layout work slightly differently. idk which is
  // the best layout to use with dynamic content and we don't have
  // edge-case designs so ¯\_(ツ)_/¯
  height: ${(p) => p.lines * p.fontSize * p.lineHeight}px;
  line-height: ${(p) => p.lineHeight};
`

const Title = styled(ClampedText).attrs({
  innerElement: 'h2',
  fontSize: 22,
  lineHeight: 1.15
})`
  font-weight: 700;
  letter-spacing: -0.5px;
`

const InfoText = styled(ClampedText).attrs({
  innerElement: 'span',
  fontSize: 14,
  lineHeight: 1.25,
  lines: 3
})`
  margin: 0;
  color: ${colors.summaryTextColor};
`

const LinkText = styled.span`
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
  line-height: 1.5;
  text-transform: uppercase;
`

const ArrowRight = styled(SmallArrowRight)`
  margin-left: 5px;
  vertical-align: middle;
`

const Tile = (props) => {
  const {
    badgeProps,
    image,
    infoText,
    isLogoImage,
    linkText,
    title,
    to,
    isPlusExclusive,
    isWhatsOn
  } = props
  const ImageComp = isLogoImage ? LogoImage : FullBleedImage
  const showSavedHeart = showHeartResourceTypes.includes(props.resourceType)

  const content_id = props.contentfulId
  const content_type = props.resourceType
  const savedId = _get(
    _.find(
      props.saved,
      (item) =>
        item.content_id === content_id && item.content_type === content_type
    ),
    'id',
    null
  )
  const imageOptions = {
    width: tileVars.tileWidth * 2,
    height: tileVars.imageHeight * 2,
    fit: 'fill'
  }
  return (
    <PlainLi>
      <BaseTile to={to || '#'} isWhatsOn={isWhatsOn}>
        <ImageBox>
          <ImageComp src={getAssetSrc(image, imageOptions)} />
          {badgeProps ? <DateTimeBadge as={'time'} {...badgeProps} /> : null}
          {showSavedHeart && isCordova && (
            <SavedHeart
              saved={savedId}
              handleOnHeartClick={() =>
                props.handleOnHeartClick({
                  savedId,
                  content_id,
                  content_type
                })
              }
            />
          )}
          {isPlusExclusive && <PlusExclusiveBadge transparent />}
        </ImageBox>
        <TextBox>
          <Title lines={infoText ? 2 : 4}>{title}</Title>
          {infoText ? <InfoText>{infoText}</InfoText> : null}
          <LinkText>
            {linkText}
            <ArrowRight />
          </LinkText>
        </TextBox>
      </BaseTile>
    </PlainLi>
  )
}

const ResourceTile = (props) => {
  const { resource, history, match, location, isWhatsOn } = props
  const { translate, moment, translateUrl, defaultLocale } = useInternationalisation()
  const tileProps = React.useMemo(() => {
    return getTileProps({ resource, translate, match, moment, defaultLocale })
  }, [resource, location.pathname])
  const saved = useSelector(savedSelector())
  const userAttributes = useSelector(attributesSelector())

  const dispatch = useDispatch()
  const handleOnHeartClick = useCallback(
    ({ savedId, content_id, content_type }) => {
      if (!userAttributes) history.push(translateUrl('/saved'))
      if (!savedId) {
        dispatch(
          createSaved({
            content_id,
            content_type
          })
        )
      } else {
        dispatch(
          removeSaved({
            id: savedId
          })
        )
      }
    },
    []
  )

  if (_.isEmpty(tileProps)) return null

  return (
    <Tile
      {...tileProps}
      saved={saved}
      handleOnHeartClick={handleOnHeartClick}
      isWhatsOn={isWhatsOn}
    />
  )
}

export default React.memo(withRouter(ResourceTile))
