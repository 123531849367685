import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { createHashHistory } from 'history'

import en from 'src/config/configFiles/translations/en'
import fr from 'src/config/configFiles/translations/fr'
import FuturaRegular from 'src/assets/fonts/Futura-Regular.ttf'
import FuturaBold from 'src/assets/fonts/Futura-Bold.ttf'
import { isCordova, isBrowser } from 'src/env'
import { RESET as RESOURCES_RESET } from 'src/store/resources/constants'

const textColor = '#222'

const Wrapper = styled.main`
  @font-face {
    font-family: Futura;
    src: url(${FuturaRegular}) format('truetype');
    font-weight: regular;
    font-display: block;
  }
  @font-face {
    font-family: Futura;
    src: url(${FuturaBold}) format('truetype');
    font-weight: bold;
    font-display: block;
  }
  align-items: center;
  color: ${textColor};
  display: flex;
  flex-direction: column;
  font-family: Futura, Arial;
  height: 100vh;
  justify-content: center;
  line-height: 1.4;
  text-align: center;
  width: 100vw;
`

const Title = styled.div`
  font-weight: bold;
  font-size: 22px;
`

const Text = styled.div`
  font-size: 20px;
`

const LoadingText = styled(Text)`
  opacity: 0.5;
  font-weight: bold;
`

// we're not using the Button from components
// because this CrashScreen needs to be as uncrashable
// as possible
const Button = styled.button`
  background-color: ${textColor};
  border: 0;
  color: white;
  cursor: pointer;
  display: block;
  font-weight: bold;
  margin-top: 15px;
  outline: 0;
  padding: 10px;
  padding: 0 25px;
  font-size: 18px;
  line-height: 40px;
  text-transform: uppercase;
`

const translationResources = { en, fr }

const safeTranslate = (key) => {
  let lang
  if (isBrowser) {
    lang = _.get(navigator, 'language')
  }

  if (typeof lang === 'string') {
    [lang] = lang.split('-')
  }

  let translationResource = translationResources[lang]
  if (!translationResource) {
    translationResource = translationResources.en
  }

  const translated = translationResource[key]
  return translated || key
}

const CrashScreen = (props) => {
  const { clearError, dispatch } = props

  const [isLoading, setIsLoading] = React.useState(false)

  const handleReset = React.useCallback(() => {
    if (isBrowser) {
      if (isCordova) {
        setIsLoading(true)
        const history = createHashHistory()
        history.push('/')
        dispatch({ type: RESOURCES_RESET })
        setTimeout(() => {
          setTimeout(clearError, 0)
        }, 1000)
      } else {
        window.location.href = '/'
      }
    }
  })

  const translationPrefix = isCordova ? 'CRASH_SCREEN_APP' : 'CRASH_SCREEN_WEB'

  return (
    <Wrapper>
      {isLoading ? (
        <LoadingText>
          {safeTranslate(`${translationPrefix}_LOADING`)}
        </LoadingText>
      ) : (
          <>
            <Title>{safeTranslate(`${translationPrefix}_TITLE`)}</Title>
            <Text>{safeTranslate(`${translationPrefix}_TEXT`)}</Text>
            <Button onClick={handleReset}>
              {safeTranslate(`${translationPrefix}_BUTTON`)}
            </Button>
          </>
        )}
    </Wrapper>
  )
}

export default CrashScreen
