import memoize from 'lodash/memoize'
import { parseQueries } from './utils'
import _get from 'lodash/get'
import { isCordova } from 'src/env'
import SelectField from 'src/components/Fields/Select'
import _map from 'lodash/map'
import _startCase from 'lodash/startCase'
import _toLower from 'lodash/toLower'
import _invert from 'lodash/invert'
import _includes from 'lodash/includes'
import _compact from 'lodash/compact'
import _upperFirst from 'lodash/upperFirst'
import _isArray from 'lodash/isArray'
import fp from 'lodash/fp'
import { fetchResources } from 'src/store/resources/actionCreators'
import { getUrlTranslators } from 'src/context/internationalisation'
import { getParentCategoryAlias } from 'src/utility'

const getParentCategoryWhereClause = ({ match, untranslateUrl }) => {
  const parentCategory = getParentCategoryAlias(
    untranslateUrl(match.params.parentCategory)
  )
  return {
    [`fields.parentCategory${
      _isArray(parentCategory) ? '[in]' : ''
      }`]: _isArray(parentCategory)
        ? parentCategory.map(_upperFirst).join(',')
        : _upperFirst(parentCategory)
  }
}

export const getResourceConfig = memoize(function ({
  match,
  location,
  promotionTypes,
  untranslateUrl
}) {
  let { parentCategory } = match.params
  parentCategory = untranslateUrl(parentCategory)
  const isRetailUnit = _includes(
    ['dine', 'shop', 'shop-and-dine'],
    parentCategory
  )
  const now = new Date().toISOString()
  const {
    category: categoryQuery,
    store: storeQuery,
    tab: tabQuery
  } = parseQueries({
    location
  })
  const key = _toLower(`${parentCategory}${tabQuery ? `.${tabQuery}` : ''}`)

  const universalWhere = {
    ...(isRetailUnit
      ? {
        ...getParentCategoryWhereClause({ match, untranslateUrl }),
        'fields.showInStoreDirectory': true
      }
      : {
        [`fields.${
          isCordova ? 'publishOnPlusApp' : 'publishOnWebsite'
          }`]: true,
        'fields.displayFrom[lte]': now
      })
  }
  const universalSelect = [
    'fields',
    // Errors when content model is missing requested fields
    // ...(isRetailUnit
    //   ? [
    //       'fields.name',
    //       'fields.logoImage',
    //       'fields.openingTimes',
    //       'fields.overwriteWithReferencedOpeningHours',
    //       'fields.referencedOpeningTimes',
    //       'fields.parentCategory',
    //       'fields.categories'
    //     'fields'
    //   ]
    //   : _compact([
    //     'fields.title',
    //     'fields.listImage',
    //     'fields.summary',
    //     'fields.categories',
    //     parentCategory === 'news' ? null : 'fields.isPlusExclusive'
    //   ])),
    'fields.urlName'
  ]

  const categoryModels = {
    retailUnitCategory: {
      getSchema: (options, resources) => {
        /**
        * @type {string[]} An Array of unique categories (home, fashion) pulled from all the jobs returned from contentful.
        */
        const populatedCategories = fp.compose(
          fp.uniq,
          fp.map('fields.urlName'),
          fp.flatMap('fields.categories')
        )(resources)
        /**
        * @type {object[]} Filtered array of categories that have retail units belonging to them.
        */
        const categoryOptions = fp.filter(
          fp.compose(
            (id) => {
              return _includes(populatedCategories, id)
            },
            fp.get('value')
          )
        )(options)
        return ({
          component: SelectField,
          props: {
            label: 'ALL_CATEGORIES',
            options: categoryOptions,
            allowNullValue: true
          },
          key: 'category'
        })
      }
    }
  }
  const promotions = {
    resourceType: 'promotion',
    enableSearch: true,
    aToZ: false,
    cordovaOnly: false,
    noFetch: true,
    order: true,
    categoryModels: {
      promotion: {
        groupBy: 'fields.categoryType',
        groupByMapping: {
          competition: 'competitions',
          offer: 'offers',
          reward: 'rewards'
        },
        getSchema: () => ({
          component: SelectField,
          isLink: true,
          key: 'categoryType',
          props: {
            label: 'ALL_PROMOTIONS',
            options: _map(promotionTypes, ({ title, link }) => ({
              value: link,
              label: title
            })),
            allowNullValue: false
          }
        })
      },
      ...categoryModels
    }
  }
  const retailUnit = {
    resourceType: 'retailUnit',
    enableSearch: true,
    aToZ: true,
    cordovaOnly: false,
    filterCategoriesByParent: true,
    where: universalWhere,
    select: universalSelect,
    categoryModels
  }

  const config = _get(
    {
      shop: { ...retailUnit, enableShopOnlineButton: true },
      dine: retailUnit,
      leisure: retailUnit,
      'shop-and-dine': { ...retailUnit, cordovaOnly: true },
      events: {
        resourceType: 'event',
        enableSearch: true,
        aToZ: false,
        cordovaOnly: false,
        where: {
          ...universalWhere,
          'fields.endDate[gte]': now
        },
        select: [...universalSelect, 'fields.endDate', 'fields.startDate'],
        categoryModels,
        order: 'fields.stickyOrdering,-fields.displayFrom'
      },
      account: {
        events: {
          resourceType: 'event',
          enableSearch: false,
          noResultText: 'NO_RESULTS_FOUND_MY_EVENTS',
          aToZ: false,
          userCollection: { key: 'user_events', filterBy: 'event_id' },
          where: {
            ...universalWhere,
            'fields.endDate[gte]': now
          },
          select: [...universalSelect, 'fields.endDate', 'fields.startDate'],
          order: 'fields.stickyOrdering,-fields.displayFrom'
        },
        rewards: {
          resourceType: 'promotion',
          enableSearch: false,
          noResultText: 'NO_RESULTS_FOUND_MY_REWARDS',
          aToZ: false,
          categoryType: 'Reward',
          userCollection: {
            key: 'user_promotions',
            filterBy: 'promotion_id'
          },
          where: {
            ...universalWhere,
            'fields.endDate[gte]': now
          },
          select: [...universalSelect, 'fields.endDate', 'fields.startDate'],
          order: 'fields.stickyOrdering,-fields.displayFrom'
        },
        competitions: {
          resourceType: 'promotion',
          enableSearch: false,
          noResultText: 'NO_RESULTS_FOUND_MY_COMPETITIONS',
          aToZ: false,
          categoryType: 'Competition',
          userCollection: {
            key: 'user_promotions',
            filterBy: 'promotion_id'
          },
          where: {
            ...universalWhere,
            'fields.endDate[gte]': now
          },
          select: [...universalSelect, 'fields.endDate', 'fields.startDate'],
          order: 'fields.stickyOrdering,-fields.displayFrom'
        }
      },
      promotions,
      offers: promotions,
      competitions: promotions,
      rewards: promotions,
      news: {
        resourceType: 'news',
        enableSearch: true,
        aToZ: false,
        cordovaOnly: false,
        where: universalWhere,
        select: [...universalSelect, 'fields.displayFrom'],
        categoryModels,
        order: 'fields.stickyOrdering,-fields.displayFrom'
      },
      jobs: {
        resourceType: 'job',
        enableSearch: true,
        aToZ: false,
        cordovaOnly: false,
        where: {
          'fields.applicationCloseDate[gte]': now
        },
        categoryModels: {
          retailUnit: {
            field: 'fields.retailUnit',
            getSchema: (options, resources) => {
              /**
               * @type {string[]} An Array of unique store Ids pulled from all the jobs returned from contentful.
               */
              const hiringStoresIds = fp.compose(
                fp.uniq,
                fp.map('fields.retailUnit.sys.id')
              )(resources)
              /**
               * @type {object[]} Filtered array of retail units which have an Id that belongs to `hiringStoresIds`.
               */
              const hiringStoreOptions = fp.filter(
                fp.compose(
                  (id) => {
                    return _includes(hiringStoresIds, id)
                  },
                  fp.get('value')
                )
              )(options)
              return {
                component: SelectField,
                key: 'store',
                props: {
                  label: 'ALL_STORES',
                  options: hiringStoreOptions,
                  allowNullValue: true
                }
              }
            }
          },
          jobCategory: {
            field: 'fields.category',
            getSchema: (options) => ({
              component: SelectField,
              key: 'category',
              props: {
                label: 'ALL_CATEGORIES',
                options,
                allowNullValue: true
              }
            })
          }
        }
      }
    },
    key
  )
  if (!config) throw new Error(`no Listing config matching ${parentCategory}`)
  return config
})

export const getRequestKey = ({
  location,
  match,
  resourceType,
  host,
  untranslateUrl
}) => {
  if (!untranslateUrl)
    untranslateUrl = getUrlTranslators({ host }).untranslateUrl
  return [
    resourceType ||
    getResourceConfig({
      match,
      location,
      untranslateUrl
    }).resourceType
  ].join('/')
}

export const fetchTiles = ({
  location,
  match,
  dispatch,
  next,
  host,
  locale
}) => {
  const { untranslateUrl } = getUrlTranslators({ host })
  const { where, resourceType, select, noFetch, order } = getResourceConfig({
    match,
    location,
    untranslateUrl
  })
  if (noFetch) return Promise.resolve()
  const requestKey = getRequestKey({ location, match, host })
  return dispatch(
    fetchResources({
      locale,
      resourceType,
      requestKey,
      host,
      where,
      next,
      limit: 1000,
      select,
      order
    })
  )
}

export const fetchCategories = ({
  location,
  match,
  dispatch,
  host,
  locale
}) => {
  const { untranslateUrl } = getUrlTranslators({ host })
  const {
    categoryModels,
    filterCategoriesByParent,
    noFetch
  } = getResourceConfig({
    match,
    location,
    untranslateUrl
  })
  return _map(categoryModels, (_, resourceType) => {
    if (noFetch) return Promise.resolve()
    return dispatch(
      fetchResources({
        locale,
        resourceType,
        requestKey: getRequestKey({
          location,
          match,
          resourceType,
          host
        }),
        host,
        ...(filterCategoriesByParent && {
          where: getParentCategoryWhereClause({ match, untranslateUrl })
        }),
        limit: 1000,
        order: resourceType === 'retailUnit' ? 'fields.name' : 'fields.title'
      })
    )
  })
}
